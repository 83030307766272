

  import { defineComponent, reactive, ref, onMounted } from 'vue';
  import SvgIcon from '@/components/SvgIcon.vue';
  import NotePopup from '@/components/NotePopup.vue';
  import sweetChaseImage from '@/assets/images/gamefi/sweet-chase.png';
  import throneImage from '@/assets/images/gamefi/throne.png';
  import getMeHighImage from '@/assets/images/gamefi/get-me-high.png';
  import Web3Provider from '../../utils/Web3Provider';
  import request from '@/utils/request';
  import LoadingOverlay from '@/components/LoadingOverlay.vue';
    import {
    Toast,
  } from 'vant';
  export default defineComponent({
    name: 'gameFi',
    components: { SvgIcon, NotePopup, LoadingOverlay },
    setup() {
      const state = reactive({
        listLoad: false,
        finished: true,
        noteShow: false,
        gameUrl: '',
      });

      const list = ref();
      list.value = [
        {
          picUrl: getMeHighImage,
          gameName: 'Get Me High',
          power: '100%',
          gameUrl: '/games/tower',
          tags: [
            'NFT', 'CASUAL', 'PUZZLE',
          ],
          disabled: false,
        },
        {
          picUrl: throneImage,
          gameName: 'Throne',
          power: '100%',
          gameUrl: 'https://tt.mfofo.com/games/ThrOne',
          tags: [
            'MOBA', 'Metaverse', 'WAR',
          ],
          disabled: false,
        },
        {
          picUrl: sweetChaseImage,
          gameName: 'Sweet Chase',
          power: '100%',
          gameUrl: '/games/sweetchase',
          tags: [
            'NFT', 'Adventurer', 'Sweet',
          ],
          disabled: false,
        },

      ];
      const userAddress = ref();
      userAddress.value = '';

      const balanceNum = ref(0);
      const balance = ref();
      balance.value = 'Loading...';

      const loading = ref(false);

      function toPlay(index: number) {
        state.noteShow = true;
        state.gameUrl = list.value[index].gameUrl;
      }

      function handleNoteClose() {
        state.noteShow = false;
      }

      const signIn = async (callBackFunc) => {
        loading.value =  true;
        const signObj = await Web3Provider.getInstance().getSignInfo();
        if(signObj){
          await request.post('/login', signObj).then(res => {
            if(callBackFunc){
              callBackFunc();
            }
          }).catch((err) => {
            Toast.fail('Login failed');
            loading.value =  false;
          });
        } else {
          Toast.fail('Login failed');
        }
        loading.value =  false;

      };

      const getbalance = async () => {
        request.get('/getbalance', {
          params: {
            userid: userAddress.value,
          },
        }).then((res) => {
          if (res['code'] === 0) {
            balance.value = res['result'];
          }
        }).catch(async (err) => {
          if(err.response && err.response.status === 403){
            await signIn(getbalance);
          } else {
            console.error(err);
          }
          
        });
      };
      onMounted(async () => {
        userAddress.value = await Web3Provider.getInstance().getAccountAddress();
        //await signIn();
        await getbalance();
      });

      return { state, list, toPlay, handleNoteClose, balance };
    },
  });
