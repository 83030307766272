
import { defineComponent, reactive, watchEffect } from 'vue';

export default defineComponent({
  name: 'notePopup',
  props: {
    noteShow: Boolean,
    url: String,
  },
  emits: ['noteClose'],
  setup(props, context) {
    const state = reactive({
      noteShow: false,
    })

    function handleClose() {
      context.emit('noteClose');
    }

    function handleConfirm(url: string) {
      handleClose();
      // window.location.href = url;
      window.open(url);
    }

    watchEffect(() => {
      state.noteShow = props.noteShow || false;
    })
    return {
      state, handleClose, handleConfirm
    }
  }
});
